<template>
    <b-input-group class="input-pesquisa">

        <b-form-input 
            class="input-pesquisa-input"
            type="search"
            :placeholder="placeholder" 
            v-model="valor"
            tabindex="1"
            @keyup.enter="emiteFiltro"
        >
        </b-form-input>

        <b-input-group-prepend
            v-if="isAtivaBotaoLupa"
            is-text
            class="input-pesquisa-icone"
            tabindex="2"
            @keyup.enter="emiteFiltro"
            @click="emiteFiltro"
        >
            <b-icon icon="search"></b-icon>
        </b-input-group-prepend>

    </b-input-group>
</template>

<script>

    export default {
        name: 'InputPesquisa',

        data() {
            return {
                valor: ''
            }
        },

        props: {
            placeholder: {
                type: String,
                default: 'Pesquisar...'
            },
            isAtivaBotaoLupa: {
                type: Boolean,
                default: true
            }
        },

        methods:{
            emiteFiltro: function () {
                var palavra = this.valor;
                this.$emit("filtrar", palavra)
            }
        }
    }

</script>

<style scoped>
.input-pesquisa {
    width: 100%;
    height: 35px;
}
.input-pesquisa-input {
    height: 35px;
    border-radius: inherit;
    border:none;
    box-shadow: none;
    font-size: inherit;
    font-weight: bold;
    color: var(--cinza-5);
}
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    .input-pesquisa-input::placeholder {
        color: var(--cinza-5);
        opacity: 1; /* Firefox */
    }
    /* Internet Explorer 10-11 */
    .input-pesquisa-input:-ms-input-placeholder {
        color: var(--cinza-5);
    }
    /* Microsoft Edge */
    .input-pesquisa-input::-ms-input-placeholder  {
        color: var(--cinza-5);
    }
    .input-pesquisa-input:hover {
        border: 1px var(--cor-primaria-cliente) solid;
    }
    .input-pesquisa-input:active {
        border: 1px var(--cor-primaria-cliente) solid;
        border-radius: inherit;
    }

.input-pesquisa-icone {
    height: 35px;
    cursor: pointer;
}
    .input-pesquisa-icone:hover {
        opacity: 0.6;
        border: 1px var(--cor-primaria-cliente) solid;
    }

</style>