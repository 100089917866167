<template>
    <b-modal v-model="modalVisible"
             title="Perfis de usúarios com acesso permitido / Modelo de aba"
             title-tag="h6"
             hide-footer>
        <template v-slot:default="{ cancel, ok }">
            <div>
                <b-row>
                    <b-col>
                        <div v-for="(perfil, index) in listagemPerfis" :key="index">
                            <input-checkbox :label="perfil.Nome"
                                            v-model="perfil.Ativado" />
                        </div>
                    </b-col>
                    <b-col>
                        <div>
                            <b-form-radio v-for="(item, index) in perfisKanban" v-model="kanbanPerfilRadio"  name="radio_perfilKanban" :value="item.valor">{{item.perfil}}</b-form-radio>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <div>
                <botao-modal-ok type="button" @click="salvar(ok)" class="float-right ml-2">
                    Salvar
                </botao-modal-ok>
                <botao-modal-cancel type="button" class="float-right ml-2" @click="cancel()"> Cancelar </botao-modal-cancel>
            </div>

        </template>
    </b-modal>
</template>

<script>
    import InputCheckbox from '@/components/inputs/InputCheckbox.vue'
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue';
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue';

    export default {
        name: 'ModalTabulacaoKanban',
        components: {
            InputCheckbox,
            BotaoModalOk,
            BotaoModalCancel
        },
        data() {
            return {
                modalVisible: false,
                tabulacaoId: '',
                kanbanPerfilRadio: 0,
                perfisPermitidos: [],
                perfisKanban: [{ perfil: "Padrão", valor: 0 }, { perfil: "Sale", valor: 1 }, { perfil: "RH", valor: 2 }],

            }
        },
        computed: {
            listagemPerfis() {
                const usuariosPerfis = this.$store.getters['usuarios/listaUsuariosPerfis']
                if (usuariosPerfis) {
                    return usuariosPerfis.map(perfil => ({
                        ...perfil,
                        Ativado: this.perfisPermitidos.includes(perfil.Id.toString())
                    }))
                }
                return []
            },
        },
        methods: {
            show(id, perfisPermitidos, kanbanPerfil) {
                this.tabulacaoId = id
                this.perfisPermitidos = perfisPermitidos?.split(',') ?? []
                this.modalVisible = true
                this.kanbanPerfilRadio = kanbanPerfil;
            },
            salvar(ok) {
                const perfisPermitidosAtualizado = this.listagemPerfis
                    .filter(perfil => perfil.Ativado)
                    .map(perfil => perfil.Id)
                    .join(',')

                this.$emit('setPerfisPermitidos', { id: this.tabulacaoId, perfis: perfisPermitidosAtualizado, perfilKanban: this.kanbanPerfilRadio})
                ok();
            }
        },

    }
</script>

<style scoped>
</style>