import axios from 'axios'
const URL_API = '/api'

const exportarPlanilhaSimples = async (arrayDeObjetos, nomeDoArquivoComExtensao) => {
    try {
		let data = new FormData();
		Object.keys(arrayDeObjetos).forEach(key => {
			if (arrayDeObjetos[key]) data.append(key, arrayDeObjetos[key]);
		});
		data.append("IsExportacao", true);

		await axios.post(`${URL_API}/Relatorios/exportarRelatorioAtendimento`, data, {
			headers: { "Content-Type": "multipart/form-data" },
			responseType: "arraybuffer"
		}).then(res => {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(new Blob([res.data]))
            link.download = `relatorio_${nomeDoArquivoComExtensao}_${Date.now()}.xlsx`
            link.click();
            link.remove();
        }).catch(err => {
            console.log(err);
        });
    } catch (e) {
        console.log('erro exportarPlanilhaSimples', e)
    }
}

export default {
    exportarPlanilhaSimples
}