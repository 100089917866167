<template>
    <div>
        <b-modal
            class="modal-formulario-item-tabulacao"
            ref="formularioItemTabulacao"
            hide-footer
            centered
            header-class="d-none"
            content-class="rounded-0"
        >

            <b-container fluid>

                <h5 class="form-item-tabulacao-titulo">Novo/Editar item</h5>

                <b-row>
                    <b-col cols="10">
                        <input-padrao label="Título" v-model.trim="$v.titulo.$model" />
                        <p class="txt-erro-validacao" v-if="clickSalvar && $v.titulo.$invalid">Campo título é obrigátório</p>
                    </b-col>
                    <b-col>
                        <input-padrao label="Ordem" tipo="number" v-model.trim="ordem" />
                    </b-col>
                </b-row>

                <div class="w-100 mt-4 text-right">
                    <botao-modal-cancel class="d-inline mr-2" @click="fechar">Cancelar</botao-modal-cancel>
                    <b-button id="botaoSalvar" variant="success" @click="salvar" :disabled="isSending" class="rounded-0">
                        <span v-if="isSending">
                            <b-spinner small class="mr-1"></b-spinner>
                            Salvando
                        </span>
                        <span v-else>
                            Salvar
                        </span>
                    </b-button>
                </div>

            </b-container>

        </b-modal>
    </div>
</template>

<script>
    import axios from "axios";

    import { required } from "vuelidate/lib/validators";
    import InputPadrao from '@/components/inputs/InputPadrao.vue'
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue'
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue' 


    export default {
        name: 'FormItemTabulacao',
        components: {
            InputPadrao,
            BotaoModalOk,
            BotaoModalCancel
        },

        data() {
            return {
                isSending: false,
                enviou: false,
                id: '',
                empresaId: '',
                corColunaKanban: '',
                kanbanItem: '',
                tabulacaoId: '',
                titulo: '',
                ordem: 0,

                clickSalvar: false
            }
        },
        
        methods: {
            show() {
                this.$refs['formularioItemTabulacao'].show()

                return new Promise(resolve => this.enviou = resolve);
            },
            fechar() {
                this.limpar();
                this.$refs['formularioItemTabulacao'].hide();
            },
            editar(tab) {
                console.log(tab)
                this.id = tab.Id;
                this.corColunaKanban = tab.CorColunaKanban;
                this.kanbanItem = tab.KanbanItem;
                this.empresaId = tab.EmpresaId;
                this.tabulacaoId = tab.TabulacaoId;
                this.titulo = tab.Titulo;
                this.ordem = tab.Ordem;
            },
            setarTabulacao(id) {
                this.tabulacaoId = id;
            },
            limpar() {
                this.clickSalvar = false;

                this.id = '';
                this.kanbanItem = '';
                this.tabulacaoId = '';
                this.titulo = '';
                this.corColunaKanban = '',
                this.ordem = 0;
            },
            salvar() {
                this.clickSalvar = true;
                this.isSending = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                    return
                }

                let dados = {
                    "Id": this.id,
                    "KanbanItem" : this.kanbanItem,
                    "CorColunaKanban" : this.corColunaKanban,
                    "EmpresaId": this.empresaId,
                    "TabulacaoId": this.tabulacaoId,
                    "Titulo": this.titulo,
                    "Ordem": this.ordem,
                }

                if(this.id){
                    axios.put(`api/TabulacaoItem`, dados).then(response => {
                        this.enviou(true);
                        this.isSending = false;
                        this.$bvToast.toast(`Item da tabulação editado com sucesso.`, {
                            title: "Sucesso",
                            toaster: "b-toaster-top-right",
                            variant: "success",
                            solid: true,
                            autoHideDelay: 5000
                        });
                        this.fechar();
                    }).catch(error => {
                        console.error(error);
                        this.isSending = false;
                        this.fechar();
                        this.$bvToast.toast(`Ocorreu um erro ao editar item da tabulação.`, {
                            title: "Ops, algo deu errado...",
                            toaster: "b-toaster-top-right",
                            variant: "danger",
                            solid: true,
                            autoHideDelay: 5000
                        });
                    })
                }
                else{
                    delete dados.Id;
                    delete dados.EmpresaId;
                    axios.post(`api/TabulacaoItem`, dados).then(response => {
                        this.enviou(true);
                        this.isSending = false;
                        this.$bvToast.toast(`Item da tabulação criado com sucesso.`, {
                            title: "Sucesso",
                            toaster: "b-toaster-top-right",
                            variant: "success",
                            solid: true,
                            autoHideDelay: 5000
                        });
                        this.fechar();
                    }).catch(error => {
                        console.error(error);
                        this.isSending = false;
                        this.fechar();
                        this.$bvToast.toast(`Ocorreu um erro ao criar item da tabulação.`, {
                            title: "Ops, algo deu errado...",
                            toaster: "b-toaster-top-right",
                            variant: "danger",
                            solid: true,
                            autoHideDelay: 5000
                        });
                    })
                }
                this.fechar();
            },
        },

        validations: {
            titulo: { required },
        },
    }
</script>

<style scoped>
    .form-item-tabulacao-titulo {
        color: var(--cor-primaria-cliente);
    }

    .modal-content {
        border-radius: unset !important;
        border: none !important;
    }

    .modal-content-item {
        border-radius: unset;
        border: none;
    }

    label {
        font-size: 14px;
        margin: 5px 0 2px 0;
    }

    .txt-erro-validacao {
        color: red;
        font-size: 12px;
    }
</style>