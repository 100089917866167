<template>
    <b-button :disabled="disabled" :style="{'background-color': bgColor}" class="botao-padrao-cinza px-3" v-on:click="$emit('click')">
        <slot></slot>
    </b-button>
</template>

<script>
export default {
    name: 'BotaoPadraoCinza',
    props: {
        texto: String,
        bgColor: { String, default: 'var(--cinza-4)'},
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
    .botao-padrao-cinza {
        cursor: pointer;
        border: none;
        color: var(--cinza-5);
        height: 35px;
        font-size: 14px;
        font-weight: bolder;
        border-radius: inherit;
    }

        .botao-padrao-cinza:active {
            color: var(--cinza-5);
        }

        .botao-padrao-cinza:hover {
            opacity: 0.7;
        }

        .botao-padrao-cinza:disabled {
            background-color: var(--cinza-4);
        }
</style>
